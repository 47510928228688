.header {
    display: flex;
    justify-content: space-between;
}

.left-h {
    padding: 30px;
    padding-top: 2.5rem;
    flex: 3;
    display: flex;
    gap: 2rem;
    flex-direction: column;
}

.right-h {
    flex: 1 1;
    background-color: #f48916;
    position: relative;
}

.the-best {
    margin: 60px 0 45px 0;
    background-color: #393d42;
    width: fit-content;
    color: aliceblue;
    border-radius: 4rem;
    padding: 20px 15px;
    display: flex;
    justify-content: flex-start;
    position: relative;
    align-items: center;
    width: -webkit-fit-content;
}

.the-best__fixed {
    position: absolute;
    background-color: #f48916;
    width: 84px;
    left: 5px;
    border-radius: 3rem;
    height: 80%;
}

.the-best__title {
    z-index: 2;
}

.header-text {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding-top: 20px;
    text-transform: uppercase;
    text-overflow: inherit;
    color: aliceblue;
    font-size: 80px;
    font-weight: bold;
}

.header-text__line {
    padding-bottom: 50px;
}

.header-title {
    margin-top: 10px;
    font-size: 1.6rem;
    font-weight: 200;
    width: 80%;
    letter-spacing: 1px;
    text-transform: none;
}

.figures {
    display: flex;
    gap: 2rem;
    margin-top: 10px;
}

.figures-num__data {
    font-size: 3rem;
    color: aliceblue;
    margin-bottom: 15px;
}

.figures-num__title {
    font-size: 2rem;
    text-transform: uppercase;
    color: var(--gray);
}
.header-button {
    display: flex;
    gap: 2rem;
}

.first-but {
    background-color: #f48916;
    color: #fff;
    width: 12rem;
}

.second-but {
    background-color: initial;
    border: 2px solid #f48916;
    color: #fff;
    width: 12rem;
}

.but-join {
    position: absolute;
    right: 4rem;
    top: 3rem;
}

.but-join a {
    text-decoration: none;
    color: #000;
}

.heart {
    justify-content: center;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background-color: #464d53;
    padding: 1rem;
    width: fit-content;
    top: 110px;
    position: absolute;
    left: 110px;
    gap: 2rem;
    width: 90px;
    height: 120px;
}

.heart-img {
    width: 30px;
}

.heart-litte {
    color: #9c9c9c;
}

.heart-bpm {
    color: #fff;
    font-size: 2rem;
}

.hero-img {
    position: absolute;
    width: 33rem;
    top: 18rem;
    right: 15rem;
    z-index: 1;
}

.hero-back {
    position: absolute;
    top: 5rem;
    right: 30rem;
    width: 25rem;
}

.calories {
    background-color: #656565;
    display: flex;
    border-radius: 5px;
    position: absolute;
    top: 51rem;
    right: 43rem;
    padding: 1.4rem;
    gap: 2.5rem;
}

.calories-img {
    width: 4.5rem;
}

.calories-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.calories-list__num {
    color: #9c9c9c;
}

.calories-list__kcal {
    color: #fff;
    font-size: 2.3rem;
}

.header-blur {
    height: 50rem;
    left: 0;
    width: 40rem;
}

@media screen and (max-width: 768px) {
    .header {
        flex-direction: column;
    }
    .header-blur {
        width: 20rem;
    }
    .the-best {
        margin-top: 0;
        font-size: small;
        align-self: center;
        transform: scale(0.8);
    }
    .header-text {
        font-size: xx-large;
        align-items: center;
        justify-content: center;
        gap: 20px;
        padding: 0;
    }
    .header-text__line {
        padding-bottom: 20px;
    }
    .header-title {
        font-size: small;
        text-align: center;
    }
    .header-button {
        justify-content: center;
    }
    .figures {
        text-align: center;
    }
    .figures-num__data {
        font-size: large;
    }
    .figures-num__title {
        font-size: small;
    }
    .right-h {
        position: relative;
        background: none;
    }
    .heart {
        left: 2rem;
        top: 2rem;
        z-index: 1;
    }
    .hero-img {
        position: relative;
        left: 11rem;
        width: 19rem;
        top: 7rem;
    }
    .hero-back {
        top: 0;
        left: 4rem;
        width: 20rem;
    }
    .calories {
        position: relative;
        top: 10rem;
        left: 5rem;
        width: 200px;
    }
    .calories-img {
        width: 4rem;
    }
}
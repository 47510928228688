.join {
    display: flex;
    padding: 0 30px;
    gap: 50px;
    position: relative;
}

.left-join {
    color: #fff;
    font-size: 50px;
    text-transform: uppercase;
    font-weight: 700;
}

.left-join hr {
    position: absolute;
    border: 2px solid var(--orange);
    width: 175px;
    border-radius: 20%;
    margin: -25px 0;
}

.join-text {
    padding-bottom: 40px;
}

.right-join {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0 0 20px 120px;
}

.join-email {
    display: flex;
    background-color: gray;
    padding: 15px 35px;
    gap: 35px;
}

.input-email {
    background-color: transparent;
    outline: none;   
    border: none;
    color: var(--lightgray);
}

::placeholder {
    color: var(--lightgray);
}

.button-email {
    background-color: var(--orange);
    color: #fff;
}

@media screen and (max-width: 768px) {
    .join {
        flex-direction: column;
        gap: 0;
    }
    .left-join {
        font-size: x-large;
        line-height: 0;
    }
    .right-join {
        padding: 2rem;
    }
}
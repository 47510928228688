.season {
    display: flex;
    gap: 9rem;
    padding: 0 30px;
}

.left-season {
    display: grid;
    flex: 1 1;
    gap: 1.5rem;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(3,1fr);
}

.number-one {
    /* grid-row: 1/3; */
    height: 445px;
    width: 190px;
}

.number-two {
    /* grid-column: 2/4; */
    height: 260px;
    width: auto;
    position: absolute;
    left: 240px;
}

.number-three {
    /* grid-column: 2/3; */
    /* grid-row: 2; */
    height: 170px;
    position: absolute;
    left: 240px;
    margin-top: 275px;
    width: 220px;
}

.number-four {
    /* grid-column: 3/4;
    grid-row: 2; */
    position: absolute;
    left: 480px;
    margin-top: 275px;
    height: 170px;
    width: 168px;
}

.right-season {
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-transform: uppercase;
    flex: 1 1;
}

.reason-title {
    color: var(--orange);
    font-weight: 700;
}

.reason-text {
    color: #fff;
    font-size: 5rem;
    font-weight: 700;
    margin-top: 15px;
}

.details {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 15px;
}

.details-line {
    display: flex;
    gap: 1.5rem;
}

.details-img {
    width: 35px;
}

.details-title {
    color: #fff;
    font-weight: 700;
}

.details-partner {
    color: var(--gray);
    font-size: 16px;
}

.details-logo {
    display: flex;
    gap: 1.5rem;
}

.details-logo img {
    width: 40px;
}

@media screen and (max-width: 768px) {
    .season {
        flex-direction: column;
    }
    .left-season {
        overflow: hidden;
    }
    .number-one {
        height: 250px;
        width: 100px;
    }
    .number-two {
        height: 130px;
        width: 230px;
        left: 150px;
    }
    .number-three {
        width: 111px;
        height: 100px;
        left: 150px;
        margin-top: 150px;
    }
    .number-four {
        width: 111px;
        height: 100px;
        left: 270px;
        margin-top: 150px;
    }
    .reason-text {
        line-height: 60px;
    }
}
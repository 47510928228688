.testtimonials {
    display: flex;
    padding: 0 30px;
    gap: 2rem;
}

.left-testtimonial {
    color: #fff;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    flex: 1.1 1;
}

.testtimonial-topic {
    font-weight: 700;
    color: var(--orange);
    font-size: 16px;
}

.testimonial-text {
    font-size: 50px;
    font-weight: 700;
    margin: 30px 0;
}

.testtimonial-review {
    letter-spacing: 1.5px;
    font-size: 17px;
    line-height: 40px;
    text-transform: none;
}

.testtimonial-tus {
    margin-top: 10px;
    font-weight: 500;
}

.testtimonial-name {
    color: var(--orange);
}

.right-testtimonial {
    display: flex;
    flex: 1 1;
    position: relative;
}

.border-img {
    border: 2px solid orange;
    background: initial;
    left: 140px;
    top: 15px;
    height: 320px;
    position: absolute;
    width: 275px;
}

.background-img {
    background: var(--planCard);
    left: 180px;
    top: 60px;
    height: 320px;
    position: absolute;
    width: 275px;
}

.testtimonial-img {
    object-fit: cover;
    width: 275px;
    height: 320px;
    left: 160px;
    position: absolute;
    top: 35px;
}

.testtimonial-arrow {
    position: absolute;
    display: flex;
    bottom: 1rem;
    left: 3rem;
    gap: 1.5rem;
}

.testt-left, .testt-right {
    width: 25px;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .testtimonials {
        flex-direction: column;
    }
    .testimonial-text {
        font-size: xx-large;
        margin: 20px 0;
    }
    .right-testtimonial {
        display: flex;
        flex-direction: column;
    }
    .border-img, .background-img {
        display: none;
        position: relative;
    }
    .testtimonial-img {
        position: relative;
        top: 0;
        left: 0;
        align-self: center;
        padding-bottom: 50px;
    }
    .testtimonial-arrow {
        padding-bottom: 10px;
        left: 0;
        align-self: center;
        position: relative;
    }
}
.container {
    display: flex;
    flex-direction: column;
    gap: 80px;
    padding: 0 30px;
    position: relative;
}

.container-header {
    display: flex;
    justify-content: center;
    gap: 60px;
    font-size: 4.5rem;
    font-style: italic;
    font-weight: 700;
    color: #fff;
}

.container-plans {
    display: flex;
    gap: 50px;
    align-items: center;
    justify-content: center;
}

.container-plan {
    display: flex;
    flex-direction: column;
    background-color: var(--caloryCard);
    gap: 30px;
    padding: 20px;
    width: 260px;
    color: #fff;
}

.container-plan svg {
    fill: var(--orange);
    height: 3rem;
    width: 3rem;
}

.container-plan:nth-child(2) {
    background:var(--planCard);
    transform: scale(1.1);
}

.container-plan:nth-child(2) svg {
    fill: #fff;
    height: 4rem;
    width: 4rem;
}

.container-plan__name {
    font-weight: 700;
    font-size: 18px;
}

.container-plan__price {
    font-weight: 700;
    font-size: 3em;
}

.container-plan__features {
    flex-direction: column;
    gap: 20px;
    display: flex;
}

.feature {
    display: flex;
    gap: 20px;
    align-items: center;
}

.feature-logo {
    width: 20px;
}

.container-benefits {
    font-size: 14px;
}

.container-plan:nth-child(2) .button-container {
    color: var(--orange);
}

.plans-blur-1 {
    height: 44rem;
    left: 0;
    top: 8rem;
    width: 44rem;
}

.plans-blur-2 {
    height: 46rem;
    right: 0;
    top: 10rem;
    width: 45rem;
}

@media screen and (max-width: 768px) {
    .container-header {
        flex-direction: column;
        font-size: x-large;
        align-items: center;
        justify-content: center;
        gap: 30px;
        padding-top: 10px;
    }
    .container-plans {
        flex-direction: column;
    }
    .container-plan:nth-child(2) {
        transform: none;
        -webkit-transform: none;
    }
}
.footer {
    position: relative;
    padding: 10px 0;
}

hr {
    position: absolute;
    width: 100%;
    border: 1px solid var(--lightgray);
}

.footer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
    padding: 10px 20px;
    height: 350px;
}

.footer-social {
    display: flex;
    gap: 65px;
}

.footer-social img {
    width: 30px;
}

.footer-logo img {
    width: 16rem;
}

.blur-footer-left, .blur-footer-right {
    filter: blur(200px);
    bottom: 0;
    height: 24rem;
    width: 44rem;
}

.blur-footer-left {
    background: #ff7300;
    left: 15%;
}

.blur-footer-right {
    background: red;
    right: 15%;
}
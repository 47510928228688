.heading {
    display: flex;
    justify-content: space-between;
}

.logo {
    width: 16rem;
    height: 5rem;
}

.bars {
    /* display: none; */
}

.heading-menu {
    display: flex;
    list-style: none;
    gap: 3rem;
    color: aliceblue;
}

.heading-menu__item:hover  {
    color: var(--orange);
    cursor: pointer;
}
 
@media screen and (max-width: 768px) {
    .bars {
        position: fixed;
        right: 3rem;
        width: 25px;
        height: 25px;
        background-color: var(--appColor);
        padding: 1rem;
        border-radius: 5px;
        display: block;
        z-index: 9999;
    }
    .heading-menu {
        flex-direction: column;
        background-color: var(--appColor);
        padding: 2rem;
        position: fixed;
        right: 3rem;
        z-index: 9999;
    }
}
.program {
    display: flex;
    flex-direction: column;
    padding: 0 30px;
    gap: 45px;
}

.program-header {
    display: flex;
    gap: 75px;
    justify-content: center;
    font-size: 4.5rem;
    font-style: italic;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
}

.program-categories {
    display: flex;
    gap: 2rem;
}

.categories {
    background-color: gray;
    color: #fff;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    padding: 32px;
    justify-content: space-between;
    display: block;
}

.categories:hover {
    cursor: pointer;
    background: var(--planCard)
}

.categories :nth-child(1) {
    fill: #fff;
    height: 3rem;
    width: 3rem;
}

.categories-little {
    font-weight: bold;
    font-size: 18px;
    margin-top: 28px;
}

.categories-title {
    font-size: 14px;
    line-height: 30px;
    margin-top: 6px;
}

.categories-join {
    /* display: flex; */
    line-height: 30px;
    position: relative;
}

.categories-join__now {
    margin-right: 30px;
}

.categories-join__img {
    width: 18px;
    top: 8px;
    position: absolute;
}

@media screen and (max-width: 768px) {
    .program-header {
        flex-direction: column;
        gap: 20px;
        font-size: x-large;
        align-items: center;
        justify-content: center;
        margin-top: 5rem;
    }
    .program-categories {
        flex-direction: column;
    }
    .categories {
        padding: 15px 15px;
    }
}